import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Grid,
  Button,
  TextField,
  Typography,
  makeStyles,
  CardContent,
} from '@material-ui/core';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { useForm } from 'react-hook-form';
import { ideaActions } from '../../actions';
import { useWindowSize } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MultiAreaCascade, LoadingButton } from "./../../components";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.neutrals.high.medium
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 20,
    paddingBottom: theme.spacing(5)
  },
  iconBackground: {
    backgroundColor: theme.palette.primary.light,
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  delegateIcon: {
    width: 22,
  },
  about: {
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  characters: {
    margin: theme.spacing(1, 0),
    fontSize: '0.75rem',
    position: 'absolute',
    right: 10,
  },
  error: {
    color: '#f44336',
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  buttons: {
    display: 'flex',
    alingItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2)
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

function IdeaDelegateAdmin({ id, delegate, locals }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [width] = useWindowSize();
  const { t } = useTranslation();
  const { fields } = useSelector(state => state.multiAreaCascade);
  const [delegateChar, setDelegateChar] = useState('');
  const [loading, setLoading] = useState(false);
  const { errors, register, getValues, handleSubmit } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    dispatch(ideaActions.setIdDelegate(id));

    return () => {
      dispatch(ideaActions.clearDelegate());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleEvaluation() {
    const delegate = getValues().delegate;
    setDelegateChar(delegate);
  }

  function onSubmit(data) {
    setLoading(!loading);
    if (fields.filter(item => item.selectedBusinessUnit).length > 0 && data) {
      delegate.evaluatedInput = data.delegate;
      delegate.businessUnitLocal = [fields[0].selectedBusinessUnit];
      dispatch(ideaActions.sendDelegate(delegate, t('seu_encaminhamento_foi_feito_com_sucesso')));
    }
  }

  return (
    <Card className={classes.card} elevation={0}>
      <Typography className={classes.cardTitle}>
        <div className={classes.iconBackground}>
          <AssignmentTurnedInIcon color="primary" className={classes.delegateIcon} />
        </div>
        encaminhar ideia
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <CardContent style={{ padding: 0 }}>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              {/* filter */}
              <MultiAreaCascade />

              {/* end filter */}
              <div className={classes.about}>
                <div className={classes.characters}>
                  <span>
                    {delegateChar.length}/1024 {t('caracteres')}
                  </span>
                </div>
                <TextField
                  error={!!errors.delegate}
                  id="outlined-multiline-static"
                  name="delegate"
                  label={t('observacoes')}
                  multiline
                  fullWidth
                  rows={8}
                  variant="filled"
                  onChange={handleEvaluation}
                  inputRef={register({
                    maxLength: 1024,
                    required: t('a_observacao_de_encaminhar_ideia_e_obrigatorio'),
                  })}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                />
                <p className={classes.error}>
                  {errors.delegate && errors.delegate.message}
                  {errors.delegate &&
                    errors.delegate.type === 'maxLength' &&
                    t('voce_passou_o_limite_de_caracteres')}
                </p>
              </div>
              <Box className={classes.buttons}>
                {loading ? 
                  <LoadingButton />
                :
                  <Button variant="contained" color="primary" type="submit">
                    {t('encaminhar_ideia')}
                  </Button>
                }
              </Box>
            </form>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}

export { IdeaDelegateAdmin };
