import React from 'react';
import {
  makeStyles,
  Typography,
  Box,
  Avatar,
} from '@material-ui/core';
import { dateFormat, timeFormat, history } from '../../helpers';
import { AvatarWithoutPhoto } from './AvatarWithoutPhoto';

const useStyles = makeStyles((theme) => ({
  approverHeader: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  small: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
  },
  subtitle: {
    fontSize: 11,
    color: theme.palette.neutrals.low.light,
    fontWeight: 500,
  },
  mr: {
    marginRight: theme.spacing(2),
  },
  name: {
    cursor: 'pointer',
  },
  boxInformation: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 190,
    },
  },
}));

function Approver(props) {
  const classes = useStyles();
  const { approvedBy, dateAndTime } = props;

  function goToProfile() {
    history.push(`/profile/${approvedBy._id}`);
  }

  return (
    <div className={classes.approverHeader}>
      <div>
        {approvedBy?.attachment?.url ? (
          <Avatar
            alt={approvedBy?.name}
            src={approvedBy?.attachment?.url}
            className={classes.small}
          />
        ) : (
          <AvatarWithoutPhoto className={classes.mr} large></AvatarWithoutPhoto>
        )}
      </div>
      <div className={classes.boxInformation}>
        <Typography
          variant="subtitle1"
          component="h1"
          className={classes.name}
          onClick={goToProfile}
        >
          <Box
            fontWeight="fontWeightBold"
            fontSize={14}
            onClick={goToProfile}
          >
            {approvedBy?.name}
          </Box>
        </Typography>
        
        <div
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {approvedBy?.position && (
            <Typography
              variant="subtitle2"
              component="span"
              className={classes.subtitle}
            >
              {approvedBy?.position}
            </Typography>
          )}
        </div>
        {dateAndTime && (
          <Typography variant="subtitle2" component="h5">
            <Box className={classes.subtitle}>
              {dateFormat(dateAndTime)} - {timeFormat(dateAndTime)}
            </Box>
          </Typography>
        )}
      </div>
    </div>
  );
}

export { Approver };