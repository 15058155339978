import React, { useEffect, useState, useCallback } from 'react';
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Fab from "@material-ui/core/Fab"
import Box from "@material-ui/core/Hidden"
import {
  Idea,
  FeedIdentity,
  Contents,
  Ranking,
  Copyright,
  IdeaCreateBoxFeed,
  IdeaPoints,
  DialogAllCreateIdea,
  ChallengeBanner,
  FilterTimeline,
  HighlightCard,
  SkeletonCardsHome,
} from '../../components';
import { ModalWelcome } from '../../components/ModalWelcome/ModalWelcome';
import { useDispatch, useSelector } from 'react-redux';
import {
  timelineActions,
  userActions,
  ideaCreateDialogsActions,
} from '../../actions';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLastLocation } from 'react-router-last-location';
import stylesHome from "./styles"
import config from '../../config';

function HomePage() {
  const dispatch = useDispatch();
  const classes = stylesHome()
  const [ welcomePopUp, setWelcomePopUp ] = useState(false);
  const [ lastLocation ] = useState(useLastLocation());
  const ideas = useSelector((state) => state.timeline.ideas);
  const { 
    page, 
    sort,
    search,
    hasNextPage
  } = useSelector((state) => state.timeline.paging);
  const { 
    loadingUpIdea, 
    loadingUpComment
  } = useSelector(state => state.timeline);
  const { 
    details, 
    _id 
  } = useSelector((state) => state.user);
  const loading = useSelector((state) => state.timeline.loading);
  const challenges = useSelector((state) => state.timeline.challenges);

  const handleLoadHome = useCallback(() => {
    dispatch(userActions.getUserDetailsRaking());
    dispatch(timelineActions.getBannersChallenge());
    dispatch(timelineActions.getTimeline(1, { timelineDate: -1 }, search));
  }, [dispatch, search])

  useEffect(() => {
    if (lastLocation?.pathname === '/register') {
      setWelcomePopUp(true)
    }

    handleLoadHome()
  }, [lastLocation, handleLoadHome])

  useEffect(() => {
    return () => dispatch(timelineActions.clearAll());
  }, [dispatch]);

  function loadMoreIdeas() {
    if (!loading && hasNextPage) {
      dispatch(timelineActions.getTimeline(page, sort, search));
    }
  }

  function handleOpenDialogTitleDescription() {
    dispatch(ideaCreateDialogsActions.openDialogTitleDescription())
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <ModalWelcome openWelcomePopUp={welcomePopUp} />
      <DialogAllCreateIdea />
      <Grid container spacing={3}>
        <Hidden xsDown>
          <Grid item xs={4} md={3}>
            <FeedIdentity />
            <Box className={classes.fixed}>
              <IdeaPoints ideaTotal={details?.idea} walletAmmount={details?.wallet[0]?.amount} userId={_id} />
              { config.SHOW_RANKING_HOME_CARD && <Ranking ranking={details?.ranking} /> }
              <Copyright />
            </Box>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={8} md={6}>
          <Hidden smUp>
            <Fab aria-label="add" className={classes.fab} color="secondary" onClick={handleOpenDialogTitleDescription}>
              <EmojiObjectsIcon style={{ fontSize: 36 }} />
            </Fab>
          </Hidden>
          <Hidden smDown>
            <IdeaCreateBoxFeed />
          </Hidden>
          <Hidden smUp>
            {challenges?.length > 0 && (
              <ChallengeBanner />
            )}
          </Hidden>
          <FilterTimeline />
          <HighlightCard />
          <InfiniteScroll
            dataLength={ideas.length}
            next={loadMoreIdeas}
            hasMore={true}
            scrollThreshold={'90%'}
            loader={
              loading && (
                <SkeletonCardsHome />
              )
            }
          >
            {ideas.length > 0 && ideas.map((idea, index) => (
              <Idea
                index={index}
                idea={idea}
                key={idea._id}
                loadingUpIdea={loadingUpIdea}
                loadingUpComment={loadingUpComment}
              />
            ))}
          </InfiniteScroll>
        </Grid>
        <Hidden smDown>
          <Grid item xs={3} className={classes.leftFixed}>
            {challenges?.length > 0 && (
              <ChallengeBanner />
            )}
            <Box className={classes.fixed}>
              <Contents title />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  );
}

export { HomePage };
