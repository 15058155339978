import './styles.css';

import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip
} from 'recharts';
import React, { useEffect, useState } from 'react';

import { getMonth } from '../../utils/month';
import { useTheme } from '@material-ui/core';

const LineWithArea = ({ lineColors, types }) =>
  types?.map((type) => [
    <Line
      type="monotone"
      dataKey={type}
      stroke={lineColors[type]}
      strokeWidth="2"
    />,
    <Tooltip />
  ]);

function LineChartImagine({ data }) {
  const [types, setTypes] = useState([]);
  const [legends, setLegends] = useState([]);
  const [lines, setLines] = useState([]);
  const theme = useTheme();

  const lineColors = {
    Aprovadas: theme.palette.auxiliary.green.main,
    Criadas: theme.palette.auxiliary.blue.main,
    Implementadas: theme.palette.auxiliary.red.main,
    Rejeitadas: theme.palette.primary.main
  };

  const dataFormated = data.map((item) => {
    return {
      monthYear: item.monthYear,
      Aprovadas: item.ideiasAprovadas,
      Criadas: item.ideiasCriadas,
      Implementadas: item.ideiasImplementadas,
      Rejeitadas: item.ideiasRejeitadas
    }
  });

  useEffect(() => {
    const lines = dataFormated.map(({ monthYear, ...rest }) => {
      const dateSplit = `${monthYear}`.split('/');
      return {
        monthYear: `${getMonth(Number(dateSplit[0]))}/${dateSplit[1]}`,
        ...rest,
      }
    });
    setLines(lines);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const types = Object.keys(dataFormated[0]);
    types.shift();
    setTypes(types);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const legends = types.map((t) => ({
      id: t,
      value: t,
      type: 'circle',
      color: lineColors[t],
    }));

    setLegends(legends);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types]);

  const textColor = theme.palette.neutrals.low.light;

  const fill = {
    fill: textColor,
  };

  const renderColorfulLegendText = (value, entry) => {
    return <span style={{ color: textColor }}>{value}</span>;
  };


  return (
    <ResponsiveContainer height={400} width="100%">
      <ComposedChart
        data={lines}
        margin={{ top: 50, right: 10, left: 0, bottom: 5 }}
      >

        <defs>
          {types.map((type, index) => (
            <linearGradient
              key={index}
              id={String(index)}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset="5%"
                stopColor={lineColors[type]}
                stopOpacity={0.1}
              />
              <stop offset="50%" stopColor={lineColors[type]} stopOpacity={0} />
            </linearGradient>
          ))}
        </defs>
        <XAxis
          dataKey="monthYear"
          color={theme.palette.neutrals.high.lightest}
          axisLine={false}
          tickLine={false}
          tick={fill}
          dy={10}
        />
        <YAxis stroke={theme.palette.neutrals.high.lightest} tick={fill} tickLine={false} dx={-20} />
        <CartesianGrid strokeWidth="1" stroke={theme.palette.neutrals.high.lightest} />
        {legends && (
          <Legend
            formatter={renderColorfulLegendText}
            payload={legends}
          />
        )}
        {LineWithArea({ lineColors, types })}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export { LineChartImagine };
