import React from 'react'
import { 
  makeStyles,
  Box,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import { ImagineGrid } from "./../../components"
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { history } from "./../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { manageUsersActions } from "./../../actions"
import { TagPerfil } from "./../../components"

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.neutrals.high.main,
    padding: theme.spacing(3)
  }
}))

export const UserCard = ({ user }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  function handleOpenDeleteUser(userId) {
    dispatch(manageUsersActions.toggleDialogDeleteUser(userId))
  }

  return (
    <Box className={classes.card}>
      <ImagineGrid cols={12} spacing={2} container>
        <ImagineGrid item xs={9}>
          <ImagineGrid container spacing={2}>
            <TagPerfil perfil={user.typeProfile.description} />
            <Typography variant="subtitle1" component="h1" color="primary">
              { user.name }
            </Typography>
            <Typography variant="subtitle2" component="h2">
              Àrea de atuação: <b>{ user.occupationArea }</b>
            </Typography>
          </ImagineGrid>
        </ImagineGrid>
        <ImagineGrid item xs={3}>
          <ImagineGrid container cols={2} spacing={4}>
            <ImagineGrid item>
              <IconButton onClick={() => history.push(`/users/function/${user.id}`) }>
                <Tooltip title="editar função" aria-label="editar função">
                  <EditIcon />
                </Tooltip>
              </IconButton>
            </ImagineGrid>
            <ImagineGrid>
              <IconButton onClick={() => handleOpenDeleteUser(user.id)}>
                <Tooltip title="excluir função" aria-label="excluir função">
                  <DeleteIcon />
                </Tooltip>
              </IconButton>
            </ImagineGrid>
          </ImagineGrid>
        </ImagineGrid>
      </ImagineGrid>
    </Box>
  )
}
