import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
  noContent: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: "center",
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey.main}`,
    backgroundColor: theme.palette.neutrals.high.main,
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  paperContent: {
    position: "relative",
    padding: "1rem",
    marginTop: "1rem",
  },
  cardItemWrapper: {
    padding: 0,
    margin: 0,
    width: "100%"
  },
  cardItem: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important"
  },
  cardItemInner: {
    padding: "1rem"
  },
  cardItemTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.primary.main,
    marginBottom: "0.5rem"
  },
  cardItemDescription: {
    fontSize: 14,
    margin: 0,
  },
  infiniteScroll: {
    marginTop: "1rem"
  },
  seeMoreButton: {
    color: theme.palette.primary.main,
  },
  cardItemDialog: {
    maxWidth: "90%",
    maxHeight: "60%",
    margin: "auto",
  },
  closeIconDialog: {
    color: theme.palette.neutrals.low.main,
    fontWeight: "bold",
    fontSize: 16
  },
  titleInputs: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.neutrals.low.dark,
    margin: theme.spacing(2, 0),
  },
  autoCompleteStatus: {
    width: 350,
  },
  filterButtons: {
    height: 48,

    '&:first-child': {
      marginRight: 20,
    }
  },
  errorSpan: {
    position: 'absolute',
  },
  containerDate: {
    position: 'relative',
  },
  datePicker: {
    marginTop: 0,
    marginBottom: 0,
  },
  alignCheckBoxBulEmpty: {
    display: 'flex',
    alignItems: 'center'
  }
}))