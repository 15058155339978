import React, { useState } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import DescriptionIcon from '@material-ui/icons/Description';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useSelector, useDispatch } from 'react-redux';
import { authenticationActions, feedbackActions } from '../../actions';
import {
  Button,
  Avatar,
  Menu,
  MenuItem,
  Paper,
  MenuList,
  makeStyles,
  Icon,
  Box,
  Hidden,
  useTheme,
} from '@material-ui/core';
import { DialogFeedback } from '../Feedback';
import { AvatarWithoutPhoto } from '../Shared/AvatarWithoutPhoto';
import { Link } from 'react-router-dom';
import imagineIcon from '../../assets/imgs/imagine.svg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { useTranslation } from 'react-i18next';
import { truncate } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  info: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  avatarButton: {
    marginLeft: 6,
  },
  config: {
    padding: theme.spacing(3, 4),
    borderBottom: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  title: {
    fontSize: 14.22,
    fontWeight: 'bold',
  },
  logout: {
    padding: theme.spacing(3, 4),
  },
  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    marginRight: 8,
  },
  menuItemText: {
    fontSize: 14.22,
    fontWeight: 600,
    color: theme.palette.neutrals.low.medium,
    padding: theme.spacing(1, 0),
  },
  menuIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.neutrals.low.light,
  },
  perfil:{
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  }
}));

function Settings() {
  const user = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const { openFeedback } = useSelector((state) => state.feedback);
  const classes = useStyles();
  const dispatch = useDispatch(); 
  const { t } = useTranslation();
  const theme = useTheme();
  function handleCloseFeedback() {
    dispatch(feedbackActions.openFeedback(false));
  }

  function handleOpenFeedback() {
    handleClose();
    dispatch(feedbackActions.openFeedback(true));
  }

  function logout() {
    dispatch(authenticationActions.logout());
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function getFirstName(firstName) {
    const arrName = firstName.split(' ');
    if (arrName.length > 1) {
      return arrName[0];
    }
    return arrName;
  }

  return (
    <React.Fragment>
      <Button
        aria-controls="menu-profile"
        aria-haspopup="true"
        color="inherit"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        <Hidden smDown>{truncate(getFirstName(user.name))}</Hidden>
        {user.attachment?.url ? (
          <Avatar
            alt={user.name}
            src={user.attachment.url}
            className={classes.avatarButton}
          />
        ) : (
          <AvatarWithoutPhoto xlarge className={classes.avatarButton} />
        )}
      </Button>
      <Menu
        id="menu-profile"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        MenuListProps={{ disablePadding: true }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        <Paper>
          <div className={classes.info}>
            {user.attachment && user.attachment.url ? (
              <Avatar
                src={user.attachment.url}
                alt={user.name}
                style={{ marginRight: 8 }}
                className={classes.avatar}
              />
            ) : (
              <AvatarWithoutPhoto
                style={{ marginRight: 8 }}
                className={classes.avatar}
                iconStyle={{ fontSize: 50 }}
              />
            )}
            {/* <Avatar
              alt={user.name}
              src={
                
              }
              style={{ marginRight: 8 }}
              className={classes.avatar}
            /> */}
            <div>
              <div style={{ fontSize: 20.25, fontWeight: 'bold' }}>
                {user.name}
              </div>
              <div style={{ fontSize: 14.22, fontWeight: 500 }}>
                {user.position}
                {user.businessUnitLocal?.occupationArea?.name && (
                  <Box fontWeight="fontWeightMedium" fontSize={14}>
                    {t('em')} {user.businessUnitLocal?.occupationArea?.name}
                  </Box>
                )}
              </div>
              <div className={classes.perfil}>
                {(user.typeProfile.type === 'manager') || 
                  (user.typeProfile.type === 'admin') ? (
                  <div>Perfil {user.typeProfile.description}</div>
                ): null
                }
              </div>
            </div>
          </div>
          <div className={classes.config}>
            <div className={classes.title}>{t('conta').toUpperCase()}</div>
            <MenuList>
              <MenuItem
                component={Link}
                to="/user/edit"
                onClick={handleClose}
                className={classes.menuItemText}
              >
                <PersonIcon className={classes.menuIcon} />
                {t('editar_perfil')}
              </MenuItem>
              <MenuItem
                component={Link}
                to="/user/settings"
                onClick={handleClose}
                className={classes.menuItemText}
              >
                <SettingsIcon className={classes.menuIcon} />
                {t('configuracoes_da_conta')}
              </MenuItem>
            </MenuList>
            <div className={classes.title}>IMAGINE</div>
            <MenuList>
            {/* <div className={classes.title}>IMAGINE</div>
            <MenuList>
              <MenuItem className={classes.menuItemText}>
                <Icon className={classes.menuIcon}>
                  <img src={imagineIcon} alt="icon" />
                </Icon>
                sobre o Imagine
              </MenuItem>
              <MenuItem onClick={handleClose} className={classes.menuItemText}>
                <ContactSupportIcon className={classes.menuIcon} />
                central de ajuda
              </MenuItem>
              <MenuItem onClick={handleClose} className={classes.menuItemText}>
                <DescriptionIcon className={classes.menuIcon} />
                politícas e termos de privacidade
              </MenuItem>
            </MenuList> */}
              <MenuItem
               onClick={handleClose} 
               className={classes.menuItemText}
               component={Link}
               to="/help-center"
               >
                <ContactSupportIcon className={classes.menuIcon} />
                {t('central_de_ajuda')}
              </MenuItem>
              <MenuItem 
                className={classes.menuItemText}
                onClick={handleOpenFeedback}
              >
                <FeedbackIcon className={classes.menuIcon} />
                {t('enviar_feedback')}
              </MenuItem>
              <DialogFeedback 
                open={openFeedback}
                onClose={handleCloseFeedback}
              />
              <MenuItem onClick={handleClose} className={classes.menuItemText} component={Link} to="/regulation">
                <DescriptionIcon className={classes.menuIcon} />
                {t('politicas_e_termos_de_privacidade')}
              </MenuItem> 
            </MenuList>
          </div>
          <div className={classes.logout}>
            <MenuList>
              <MenuItem
                onClick={() => {
                  handleClose();
                  logout();
                }}
                className={classes.menuItemText}
              >
                <ExitToAppIcon
                  className={classes.menuIcon}
                  style={{ color: theme.palette.auxiliary.red.main }}
                />
                {t('sair')}
              </MenuItem>
            </MenuList>
          </div>
        </Paper>
      </Menu>
    </React.Fragment>
  );
}

export { Settings };
