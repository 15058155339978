import { Box, Button, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
    buttonSelected: {
        padding: 5,
        borderRadius: 32,
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
        padding: '0 20px'
    },
    button: {
        padding: 5,
        borderRadius: 32,
        backgroundColor: 'transparent',
        border: `.5px solid #CED4DA`,
        padding: '0 24px'
    }
}));

function SelectColaboratorsRanking() {
    const classes = useStyles();
    const { t } = useTranslation();
  const { sortUsers } = useSelector(state => state.dashboard);
    const [btnSelected, setBtnSelected] = useState(sortUsers);
    const dispatch = useDispatch();

    function handleBtnStyle(tag) {
        return tag === btnSelected ? classes.buttonSelected : classes.button;
    };

    function handleClick(tag) {
        setBtnSelected(tag);
        dispatch(dashboardActions.setSortUsers(tag));
    };

    return (<Box marginBottom={5}>
                <Button 
                    style={{ marginRight: 30 }}
                    onClick={() => handleClick('points')}
                    className={handleBtnStyle('points')}
                >
                    {t('pontos')}
                </Button>
                 <Button 
                    onClick={() => handleClick('ideas')}
                    className={handleBtnStyle('ideas')}
                >
                    {t('ideias_enviadas')}
                </Button>
            </Box>);
};

export { SelectColaboratorsRanking };
