import React from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  Card,
  makeStyles
} from '@material-ui/core';
import { dateFormat, truncate, history } from '../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions } from '../../actions/profile.actions';
import { tableCss } from './TableCss';
import { useTranslation } from 'react-i18next';
import { SkeletonProfileTable } from '../Skeletons';
import emptyStateMyIdeas from '../../assets/imgs/imagine-illustration_empty-state-my-ideas.svg';
import { CustomTooltip } from '../Shared';

const useStyles = makeStyles((theme) => ({
  emptyStateImg: {
    width: 96,
    height: 96,
  },
  emptyStateCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: 56,
  },
  emptyStateDescription: {
    fontSize: 16,
    color: theme.palette.neutrals.low.medium,
  }
}));

function ProfileTable({ ideas, paging, userId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const { t } = useTranslation();
  const styleProps = { text: t('linhas_por_pagina') };
  const tableClasses = tableCss(styleProps);
  const loading = useSelector((state) => state.profile.loading);
  const columns = [
    { id: 'code', label: t('codigo') },
    { id: 'date', label: t('data') },
    { id: 'title', label: t('titulo_da_ideia') },
    { id: 'challenge', label: t('desafio') },
    { id: 'actions', label: '' },
  ];

  function handleView(id) {
    history.push(`/idea/${id}/general`);
  }

  function handleChangePage(event, page) {
    let newPage = page + 1;
    dispatch(profileActions.setPage(newPage));
    dispatch(profileActions.getIdeasByUserId(userId, newPage, paging.limit));
  }

  function handleChangeRowsPerPage(event) {
    var limit = event.target.value || 10;
    dispatch(profileActions.setLimit(limit));
    dispatch(profileActions.getIdeasByUserId(userId, 1, limit));
  }

  return (
    <>
      <Typography style={{ fontSize: 14, fontWeight: 'bold', textTransform: 'uppercase' }}>
        {t('ideias_publicadas_no_feed')}
      </Typography>
      {loading ? (
        <SkeletonProfileTable />
      ) : (
        <Paper className={tableClasses.paperRoot}>
          {ideas <= 0 ? (
            <Card elevation={0} className={classes.emptyStateCard}>
              <img 
                className={classes.emptyStateImg} 
                src={emptyStateMyIdeas} 
                alt="Empty State Ideias" 
              />
              <Typography className={classes.emptyStateDescription}>
                {t('ainda_nao_ha_ideias_por_aqui')}
              </Typography>
            </Card>
          ) : (
            <>
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="idea table"
                  classes={{ root: tableClasses.table }}
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          classes={{ root: tableClasses.tableCellHeader }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ideas &&
                      ideas.map((idea) => {
                        return (
                          <TableRow
                            hover
                            key={idea._id}
                            style={{ cursor: 'pointer' }}
                            classes={{ root: tableClasses.tableRow }}
                          >
                            <TableCell
                              classes={{ root: tableClasses.tableCellRadiusLeft }}
                            >
                              {idea.code}
                            </TableCell>
                            <TableCell classes={{ root: tableClasses.tableCell }}>
                              {dateFormat(idea.createdAt, language)}
                            </TableCell>
                            <TableCell
                              className={tableClasses.oneLine}
                              classes={{ root: tableClasses.tableCell }}
                            >
                              <CustomTooltip 
                                title={idea.name} 
                                aria-label={idea.name} 
                                placement="bottom-start"
                              >
                                <Typography 
                                  variant="subtitle2" 
                                  className={tableClasses.link} 
                                  onClick={() => handleView(idea._id)}
                                >
                              {truncate(idea.name, 30)}
                                </Typography>
                              </CustomTooltip>
                            </TableCell>
                            <TableCell
                              className={tableClasses.oneLine}
                              classes={{ root: tableClasses.tableCellRadiusRight }}
                            >
                              {idea.challenge && idea.challenge.name}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                component="div"
                classes={{
                  input: tableClasses.paginationInput,
                  spacer: tableClasses.paginationSpacer,
                  caption: tableClasses.paginationCaption,
                  toolbar: tableClasses.paginationToolbar,
                }}
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                count={paging.total}
                rowsPerPage={paging.limit}
                page={paging.page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={t('exibindo')}
              />
            </>
          )}
        </Paper>
      )}
    </>
  );
}

export { ProfileTable };
