import React from 'react';
import { 
  makeStyles, 
  Container,
  Typography,
  Grid,
  Box,
} from '@material-ui/core';
import { FooterTerms } from '../../components/Shared';
import { FaqTitle } from '../../components';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: theme.palette.neutrals.low.medium,
    background: theme.palette.neutrals.high.lightest
  },
  containerContent: {
    paddingTop: theme.spacing(4),
    minHeight: '100vh',
  },
  creationDate: {
    fontSize: 20,
    fontWeight: '600',
    paddingBottom: 30,
  },

  info: {
    fontSize: 13,
    paddingBottom: 30,
    lineHeight: 1.8
  },
  box: {
    padding: theme.spacing(2, 0),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.neutrals.high.light
  },
  title: {
    padding: theme.spacing(4, 0),
    color: theme.palette.neutrals.low.main
  },
  subTitle: {
    fontWeight: 800,
    paddingBottom: theme.spacing(2),
    color: theme.palette.neutrals.low.light
  },
  description: {
    fontSize: 15,
    lineHeight: 1.8,
    paddingBottom: theme.spacing(2),
    color: theme.palette.neutrals.low.light
  },
  link: {
    transition: '0.5s ease-in-out',
    color: theme.palette.neutrals.low.light,
    '&:hover': {
      color: theme.palette.neutrals.low.main
    }
  }
}));

function RegulationPage() {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
    <FaqTitle title="políticas de privacidade" />
      
      <Container maxWidth="lg" className={classes.containerContent}>
        <Box p={0}>
          <div className={classes.creationDate}>PROGRAMA IMAGINE</div>
          <div className={classes.subTitle}>PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS</div>
          <div className={classes.info}>
            <p>Ao interagir conosco online alguns dados pessoais seus podem ser coletados ou fornecidos por você. Esta Política de Privacidade faz parte do nosso compromisso de transparência e de uso ético dos dados pessoais: aqui você encontra os detalhes sobre como utilizamos seus dados pessoais.</p>
          </div>
          <div className={classes.subTitle}>DADOS PESSOAIS NO IMAGINE</div>
          <div className={classes.info}>
            <p>Vide abaixo para saber mais sobre a utilização de dados pessoais.</p>
          </div>
      <Box className={classes.box}>
        <Typography variant="h6" className={classes.subTitle}>
          Quais dados pessoais são utilizados? 
        </Typography>
        <Typography variant="body1" className={classes.description}>
          Você pode nos fornecer seu E-mail e seu Nome para se cadastrar na plataforma de inovação Imagine.
        </Typography>       
        <Typography variant="h6" className={classes.subTitle}>
          Para que utilizamos seus dados pessoais?
        </Typography>
        <Typography variant="body1" className={classes.description}>
          Você pode nos fornecer seu nome e e-mail para se cadastrar, se identificar nas interações internas, envio de ideias e participar de desafios na plataforma.
        </Typography>
        <Typography variant="h6" className={classes.subTitle}>
          Fundamento legal:
        </Typography>
        <Typography variant="body1" className={classes.description}>
          Consentimento
        </Typography>
      </Box>
      <Typography variant="h6" className={classes.subTitle}>
        Conservação e Eliminação de dados
      </Typography>
      <Typography variant="body1" className={classes.description}>
        Manteremos seus dados pessoais armazenados enquanto for necessário, para prestar os serviços 
        e para fins legítimos e essenciais, tais como para manter o correto funcionamento e rastreabilidade 
        das informações e cumprir as nossas obrigações legais. Conservamos alguns dos seus dados pessoais 
        enquanto permanecer ativo no portal.
      </Typography>
      <Typography variant="body1" className={classes.description}>
        Quando solicitado, eliminaremos os seus dados pessoais de modo que não o identifiquem, exceto se for 
        legalmente permitido ou obrigatório manter determinados dados pessoais, incluindo situações como as 
        seguintes:
      </Typography>
      <Typography variant="body1" className={classes.description}>
        Se formos obrigados a manter os dados pessoais para as nossas obrigações jurídicas, fiscais, de 
        auditoria e contabilidade, iremos reter os seus dados pessoais necessários pelo período exigido 
        pela legislação aplicável; 
      </Typography>
      {/* <Typography variant="body1" className={classes.description}>
      </Typography> */}
      <Typography variant="h6" className={classes.subTitle}>
        Registro de acesso ao site do Imagine
      </Typography>
      <Typography variant="body1" className={classes.description}>
        O Imagine coleta e armazena pelo período necessário os dados de acesso aos seus sites de aplicativos 
        (data, hora e IP) para cumprimento de obrigação legal imposta pelo Marco Civil da Internet 
        (art. 15, Lei nº 12.965/2014). 
      </Typography>
      <Typography variant="h6" className={classes.subTitle}>
        Cookies 
      </Typography>
      <Typography variant="body1" className={classes.description}>
        Cookies são arquivos ou informações que podem ser armazenadas em seus dispositivos, quando você 
        visita o Site. O Imagine pode utilizar <b>APENAS</b> os cookies <b>Necessários</b> para facilitar o uso e melhor adaptar seu site aos interesses 
        e necessidades de seus usuários afim de garantir o funcionamento da plataforma.  
      </Typography>
      <Typography variant="h6" className={classes.subTitle}>
        Entendendo os tipos de Cookies 
      </Typography>
      <Grid container>
        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Tipos de Cookies
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h6" className={classes.subTitle}>
            O que eles fazem?
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Necessários 
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
          Esses cookies são essenciais para que os sites e aplicativos carreguem corretamente e 
          permitem que você navegue e faça uso de todas as funcionalidades disponíveis.
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Desempenho  
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
            Esses cookies nos ajudam a entender como os visitantes interagem com sites e aplicativos, 
            fornecendo informações sobre as áreas visitadas, o tempo de visita e quaisquer problemas 
            encontrados, como mensagens de erro. 
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Funcionais  
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
            Esses cookies permitem que sites e aplicativos se lembrem das escolhas dos usuários, para 
            proporcionar uma experiência mais personalizada. Também, possibilitam que os Usuários utilizem 
            a integração com redes sociais, campos para comentários, entre outros. 
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Marketing   
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
            Esses cookies são utilizados para fornecer mais conteúdo relevante e do interesse dos usuários. 
            Podem ser utilizados para apresentar publicidade direcionada ou limitar sua veiculação. 
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="body1" className={classes.description}>
        A qualquer momento, você poderá rever a forma de utilização dos cookies, utilizando para tanto 
        as configurações de seu navegador de preferência. Para mais informações sobre como proceder em 
        relação à gestão dos cookies nos navegadores, disponibilizamos os links abaixo:  
      </Typography>
      <Typography variant="body1" className={classes.description}>
        Internet Explorer: {' '}
        <a 
          href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies" 
          target="_blank" 
          className={classes.link}
        >
          https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies
        </a> <br />
        Mozilla Firefox: {' '}
        <a 
          href="https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam" 
          target="_blank" 
          className={classes.link}
        >
          https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam
        </a> <br />
        Google Chrome: {' '}
        <a 
          href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR" 
          target="_blank" 
          className={classes.link}
        >
          https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR 
        </a> <br />
        Safari: {' '}
        <a 
          href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac" 
          target="_blank" 
          className={classes.link}
        >
          https://support.apple.com/pt-br/guide/safari/sfri11471/mac
        </a> <br />
        Caso você não permita a utilização de alguns cookies, certos serviços poderão não funcionar 
        de maneira ideal (por exemplo, a utilização de serviços que requerem o login).    
      </Typography>
      <Typography variant="h6" className={classes.subTitle}>
        Seus direitos
      </Typography>
      <Typography variant="body1" className={classes.description}>
        A Lei Geral de Proteção de Dados (LGPD) garante a você direitos sobre seus dados pessoais. 
        Para exercer esses direitos, você pode solicitar a exclusão dos seus dados do Imagine, através do e-email {' '}
      <a className={classes.link} href={"mailto:" + "suporte@imagineinovacao.com.br"}>suporte@imagineinovacao.com.br</a>
      </Typography>
<hr/><br/><br/>
          <div className={classes.creationDate}>Políticas de Segurança da Informação FCamara</div>
          <div className={classes.info}>
<p>A segurança é um dos assuntos mais importantes dentre as preocupações de qualquer empresa.</p>
<p>Corroborando com os nossos clientes, fornecedores, colaboradores e, de acordo com a Lei Geral de Proteção de Dados - Lei n. 13.709/2018 (“<b>LGPD</b>”), formulamos uma Política de Segurança de informação para implementação, desenvolvimento e assistência técnica do PROGRAMA IMAGINE. </p>
<p>A presente Política de Segurança visa garantir a proteção, a manutenção da privacidade, integridade, disponibilidade e confidencialidade das informações para aqueles que possuem acesso ao PROGRAMA IMAGINE.</p>
<p>Nesse documento apresentaremos nossas diretrizes relacionadas à privacidade e proteção dos dados dos colaboradores dos nossos clientes (“<b>Colaboradores</b>” e, individualmente, “<b>Colaborador</b>”) e procedimentos para normatizar e melhorar nossa visão e atuação em segurança dos dados dos nossos clientes e Colaboradores.</p>
<p><b>Ressaltamos que todas as informações inseridas no PROGRAMA IMAGINE serão públicas aos usuários do referido programa, sem garantir anonimato aos Colaboradores.</b></p>
<p>Considerando que, de acordo com os Termos de Uso, o intuito do PROGRAMA IMAGINE é promover a participação direta por Colaboradores dos nossos clientes (denominados individualmente como “<b>Empresa</b>”) no processo de inovação e desenvolvimento da Empresa, ao cadastrar a sua ideia no Programa, os colaboradores cedem exclusiva, irrevogável e irrestritamente à Empresa a titularidade e o uso de sua ideia para seu amplo, total, pleno e irrestrito uso, exploração e disposição, o que chamamos de “Ideia” ou “Ideias”, em conjunto ou isoladamente.  </p>
<p>A Empresa poderá implantar, a qualquer tempo e de acordo com seu exclusivo interesse, as Ideias que os Colaboradores apresentarem no PROGRAMA IMAGINE, sem qualquer tipo de restrição, tendo em vista que o resultado decorrente da Ideia apresentada será sempre de propriedade exclusiva da Empresa.</p>
<p>O Colaborador que apresentar a Ideia não terá direito a qualquer remuneração adicional ao seu salário pela sua apresentação e/ou implantação; somente terá direito à premiação específica caso a sua ideia seja escolhida como vencedora de um desafio, o qual terá as suas regras de premiação definidas de acordo com o critério estabelecido pela área criadora do referido desafio. Outras premiações podem ser aplicadas aos participantes da plataforma com base em critérios previamente definidos e comunicados pelas áreas de negócio, desde que previamente e por escrito.</p>
<p>Os Termos de Uso do Programa Imagine e as disposições de segurança da informação aqui previstas seguirão o disposto no contrato de prestação de serviços firmado entre a FCamara, como contratada, e a Empresa, na qualidade de Contratante (“<b>Contrato</b>”).</p>
          </div>

          <div className={classes.subTitle}>1. Objetivo - Responsabilidade FCamara</div>
          <div className={classes.info}>
<p>De acordo com as regras de controle do fluxo de informações, segredos comerciais e da <b>LGPD</b>, a FCamara, desenvolvedora do PROGRAMA IMAGINE, declara que não possui qualquer gerência em relação ao conteúdo disponibilizado pela Empresa ou pelos Colaboradores no sistema, de modo que em nenhuma circunstância e de nenhuma forma poderá ser responsabilizada por qualquer ideia, comentário, informação, ato, fato, técnica, estratégia, método de operação, pormenores, inovações, segredos comerciais, marcas, criações, especificações técnicas e comerciais (“<b>Dados</b>”) inseridos no PROGRAMA IMAGINE, incluindo, mas não limitando a quaisquer erros ou omissões em tais Dados ou conteúdos, ou qualquer perda ou dano de qualquer tipo incorrido como resultado da sua utilização, do acesso ou da negação de acesso aos Dados.</p>
<p>A FCamara declara, ainda, que cumpre integralmente com as suas obrigações de sigilo, nos termos do Contrato e sob quaisquer Leis aplicáveis relativas ao tratamento de dados, de acordo com os requisitos da <b>LGPD</b>, porém, não se responsabiliza por mau uso dos Dados por terceiros que tenham acesso ao sistema.</p>
<p>A Empresa (cliente) concorda em defender, isentar e até mesmo indenizar a FCamara, seus executivos, diretores, funcionários, consultores, agentes, fornecedores, prepostos e revendedores de todas e quaisquer reclamações judiciais ou extrajudiciais dos seus Colaboradores, em relação a violação dos Dados relacionados a utilização do PROGRAMA IMAGINE, exceto se comprovado dolo da FCamara no evento.</p>
<p>Em nenhum caso, a FCamara, seus gestores, diretores, consultores, agentes, sucessores e fornecedores serão responsáveis por: <b>(i)</b> qualquer perda ou dano (incluindo qualquer dano indireto, lucros cessantes, material, imaterial, danos morais, especial, incidental, punitivo ou exemplar) que não for previsível. A perda ou dano somente será considerada previsível quando ocorrer por culpa ou dolo da FCamara, judicialmente comprovada; <b>(ii)</b> qualquer reivindicação relacionada com aplicativos, sites ou sistemas de terceiros, como, mas não se limitando ao desempenho inadequado, indisponibilidade do sistema, mau uso dos Dados; e <b>(iii)</b> qualquer prejuízo, mesmo que de responsabilidade direta da FCamara, causado por força maior ou por qualquer motivo que não seja razoavelmente previsível ou esteja além do controle razoável da FCamara.</p>
          </div>

          <div className={classes.subTitle}>2. Confidencialidade</div>
          <div className={classes.info}>
<p>A FCamara garante que o acesso por ela aos Dados será realizado apenas por pessoas autorizadas. Todos os funcionários da FCamara incumbidos de exercer qualquer função envolvendo o PROGRAMA IMAGINE somente acessarão a plataforma para fins de suporte técnico e/ou mediante justificativa ou pedido da Empresa. </p>
<p>A FCamara se obriga a manter o mais absoluto sigilo com relação a quaisquer Dados a serem inseridos no PROGRAMA IMAGINE da Empresa.</p>
          </div>

          <div className={classes.subTitle}>3. Propriedade Intelectual </div>
          <div className={classes.info}>
<p>Todos os direitos autorais referentes ao PROGRAMA IMAGINE são de titularidade exclusiva da FCamara, sendo vedada à Empresa e seus Colaboradores a reprodução, a alteração e/ou o uso indevido do seu conteúdo, sem prévia autorização por escrito desta, estando o infrator sujeito às penalidades previstas em lei.</p>
<p>A FCamara e a Empresa acordam que o fato da Empresa e seus Colaboradores utilizarem o PROGRAMA IMAGINE durante a vigência do Contrato, não dá o direito a Empresa de comercializar o uso da plataforma com seus Colaboradores e/ou qualquer terceiro, devendo essa utilização se dar de forma inteiramente gratuita por seus colaboradores e não podendo ser utilizada por terceiros.</p>
<p>Os Dados são de propriedade exclusiva da Empresa.</p>
          </div>

          <div className={classes.subTitle}>4. Colaboradores</div>
          <div className={classes.info}>
<p>Os Dados inseridos no PROGRAMA IMAGINE estarão disponíveis aos usuários e não garantirão anonimato e terão acesso pela Empresa.</p>
<p>Todas as informações pessoais relativas a Colaboradores que utilizem o PROGRAMA IMAGINE serão tratadas pela FCamara em concordância com a LGPD. Os Colaboradores declaram que estão cientes e concordam com as disposições do Termo de Uso e desta Política de Segurança da Informação, sobretudo no que se refere a isenção da responsabilidade pela FCamara em relação aos possíveis prejuízos acarretados diretamente ou indiretamente pelo uso do PROGRAMA IMAGINE, seja pela Empresa ou por qualquer Colaborador.</p>

          </div>

          <div className={classes.subTitle}>5. Integridade</div>
          <div className={classes.info}>
<p>Todos as Informações Confidenciais dos Colaboradores e da Empresa que forem armazenados no PROGRAMA IMAGINE permanecerão íntegros e inalterados, inclusive eventuais comentários feitos em cada inovação implementada.</p>

          </div>

          <div className={classes.subTitle}>6. Disponibilidade</div>
          <div className={classes.info}>
<p>A FCamara garante que todos os Dados estarão disponíveis para todos os Colaboradores em qualquer tempo de uso do PROGRAMA IMAGINE.</p>

          </div>

          <div className={classes.subTitle}>7. Autenticidade</div>
          <div className={classes.info}>
<p>A FCamara garante a possibilidade de identificar o autor de cada inserção feita no PROGRAMA IMAGINE, inclusive de eventuais comentários feitos em cada inovação implementada.</p>

          </div>

          <div className={classes.subTitle}>8. Segurança</div>
          <div className={classes.info}>
<p>Para proteger todas as informações pessoais dos Colaboradores e da Empresa, a FCamara adota precauções técnicas, administrativas e organizacionais para proteger os dados pessoais da Empresa e de seus respectivos Colaboradores contra perda, uso ou acesso não autorizado. Os dados são armazenados em um ambiente operacional seguro que não é acessível ao público.</p>
<p>A FCamara envida esforços para proteger a privacidade da conta da Empresa e/ou de seus Colaboradores, mas infelizmente não pode garantir total segurança. O acesso ou uso não autorizado de conta, falha de hardware ou software e outros fatores podem comprometer a segurança das suas Informações Confidenciais a qualquer momento, por isso, é importante que a Empresa ajude a manter um ambiente seguro para todos. Além de adotar boas práticas de segurança em relação à sua conta e aos seus dados, caso você identifique ou tome conhecimento de algo que comprometa a segurança dos seus Dados, por favor entre em contato com a FCamara, pelo e-mail: <a className={classes.descriptionRegulation} href={"mailto:" + "inovacao@concremat.com.br"}>inovacao@concremat.com.br</a></p>

          </div>

          <div className={classes.subTitle}>9. Violações da Política de Segurança da informação e Sanções</div>
          <div className={classes.info}>
<p>Nos casos em que houver violação desta política, sanções administrativas e/ou legais poderão ser adotadas, sem prévio aviso.</p>

          </div>

          <div className={classes.subTitle}>10. Vigência e Validade</div>
          <div className={classes.info}>
<p>A presente política passa a vigorar a partir da celebração do Contrato entre FCamara e Empresa sendo válida pelo mesmo prazo da vigência contratual, exceto no tocante a informações que sejam consideradas confidenciais.</p>

          </div>
        </Box>
      </Container>

      <FooterTerms />
    </div>
  );
}

export { RegulationPage };
