import { ideaCreateConstans, ideaConstants } from '../constants';
import { ideaService } from '../services';
import { ideaCreateDialogsActions } from './idea-create-dialogs.actions';
import { alertActions } from './alert.actions';
import { challengeActions } from './challenge.actions';
import { getFiles } from '../helpers';

export const ideaCreateActions = {
  setTitleDescription,
  setMethodology,
  setContributors,
  setBusinessUnitLocal,
  removeBusinessUnitLocal,
  setClassification,
  dropClassification,
  clearClassification,
  setChallenge,
  setImages,
  setVideo,
  setFiles,
  setLinks,
  removeImage,
  removeFile,
  removeLink,
  removeVideo,
  createIdea,
  createDraft,
  setLocals,
  setLocalChecked,
  setLocalUnchecked,
  getDraft,
  setCanvas,
  setValueCanvas,
  setIdeaCreateChallenge,
  setSelectedBusiness,
  clearSelectedBusiness,
  clear,
  clearAll,
};

function setTitleDescription({ name, description }) {
  return (dispatch) => {
    const payload = {
      name,
      description,
    };

    dispatch({ type: ideaCreateConstans.SET_TITLE_DESCRIPTION, payload });
  };
}

function clear() {
  return (dispatch) => dispatch({ type: ideaCreateConstans.CLEAR });
}

function clearAll() {
  return dispatch => {
    dispatch({
      type: ideaCreateConstans.CLEAR_CREATE_IDEA
    })
  }
}

function setMethodology(methodologies, code) {
  return (dispatch) => {
    const payload = {
      methodologies,
      code,
    };

    dispatch({ type: ideaCreateConstans.SET_METHODOLOGY, payload });
  };
}

function setBusinessUnitLocal(businessUnitLocals) {
  return (dispatch) => {
    const payload = {
      businessUnitLocals,
    };

    dispatch({ type: ideaCreateConstans.SET_BUSINESS_UNIT_LOCAL, payload });
  };
}

function removeBusinessUnitLocal(businessUnitLocals) {
  return (dispatch) => {
    const payload = {
      businessUnitLocals,
    };

    dispatch({ type: ideaCreateConstans.REMOVE_BUSINESS_UNIT_LOCAL, payload });
  };
}

function setClassification(personalizedClassifications) {
  return (dispatch) => {
    const payload = {
      personalizedClassifications,
    };

    dispatch({ type: ideaCreateConstans.SET_CLASSIFICATION, payload });
  };
}

function dropClassification(_id) {
  return (dispatch) => {
    const payload = {
      _id,
    };

    dispatch({ type: ideaCreateConstans.DROP_CLASSIFICATION, payload });
  };
}

function clearClassification() {
  return (dispatch) => {

    dispatch({ type: ideaCreateConstans.CLEAR_CLASSIFICATION });
  };
}

function setChallenge(challenge) {
  return (dispatch) => {
    const payload = {
      challenge,
    };

    dispatch({ type: ideaCreateConstans.SET_CHALLENGE, payload });
  };
}

function setImages(images) {
  return (dispatch) => {
    const payload = {
      images,
    };

    dispatch({ type: ideaCreateConstans.SET_IMAGES, payload });
  };
}

function setVideo(video) {
  return (dispatch) => {
    const payload = {
      video,
    };

    dispatch({ type: ideaCreateConstans.SET_VIDEO, payload });
  };
}

function setFiles(files) {
  return (dispatch) => {
    const payload = {
      files,
    };

    dispatch({ type: ideaCreateConstans.SET_FILES, payload });
  };
}

function setLinks(link) {
  return (dispatch) => {
    const payload = {
      link,
    };

    dispatch({ type: ideaCreateConstans.SET_LINKS, payload });
  };
}

function removeImage(id) {
  return (dispatch) => {
    const payload = {
      id,
    };

    dispatch({ type: ideaCreateConstans.REMOVE_IMAGE, payload });
  };
}

function removeVideo() {
  return (dispatch) => {
    dispatch({ type: ideaCreateConstans.REMOVE_VIDEO });
  };
}

function removeFile(id) {
  return (dispatch) => {
    const payload = {
      id,
    };

    dispatch({ type: ideaCreateConstans.REMOVE_FILE, payload });
  };
}

function removeLink(link) {
  return (dispatch) => {
    const payload = {
      link,
    };

    dispatch({ type: ideaCreateConstans.REMOVE_LINK, payload });
  };
}

function createIdea(idea, successMessage) {
  const contributors = idea.contributors.map(({ id }) => id);
  idea.contributors = contributors;
  return (dispatch) => {
    dispatch(request());
    ideaService.sendIdeaImagine(idea).then(
      (ideaResponse) => {
        dispatch(success());
        dispatch(alertActions.success(successMessage));
        const files = getFiles(idea);
        ideaService.uploadFiles(files, ideaResponse._id).then(() => {
          dispatch({ type: ideaCreateConstans.CLEAR });
        });
        if (idea.ideaCreateChallenge) {
          dispatch(challengeActions.getChallengeById(idea.challenge._id));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: ideaCreateConstans.POST_IDEA_IMAGINE_REQUEST };
  }

  function success() {
    return { type: ideaCreateConstans.POST_IDEA_IMAGINE_SUCCESS };
  }

  function failure(error) {
    return { type: ideaCreateConstans.POST_IDEA_IMAGINE_FAILURE, error };
  }
}

function createDraft(idea, successMessage) {
  const contributors = idea.contributors.map(({ id }) => id);
  idea.contributors = contributors;
  if (idea?.challenge?.notChallenge) {
    delete idea.challenge;
  }

  return (dispatch) => {
    ideaService.drafIdeaImagine(idea).then(
      (ideaResponse) => {
        dispatch(alertActions.success(successMessage));
        const files = getFiles(idea);
        ideaService.uploadFiles(files, ideaResponse._id).then(() => {
          dispatch(success());
          dispatch({ type: ideaCreateConstans.CLEAR });
          dispatch(ideaCreateDialogsActions.close());
        });
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success() {
    return { type: ideaConstants.POST_IDEA_DRAFT_IMAGINE_SUCCESS };
  }

  function failure(error) {
    return { type: ideaConstants.POST_IDEA_DRAFT_IMAGINE_FAILURE, error };
  }
}

function setContributors(contributorsIds) {
  const payload = {
    contributorsIds,
  };
  return { type: ideaCreateConstans.SET_CONTRIBUTORS, payload };
}

function setLocals(locals) {
  return (dispatch) => {
    dispatch({
      type: ideaCreateConstans.SET_IDEA_LOCALS,
      payload: locals
    })
  }
}

function setLocalChecked(_id) {
  const payload = {
    _id,
  };

  return { type: ideaCreateConstans.SET_LOCALS_CHECKED, payload };
}

function setLocalUnchecked(_id) {
  const payload = {
    _id,
  };
  return { type: ideaCreateConstans.SET_LOCALS_UNCHECKED, payload };
}

function getDraft(id) {
  return (dispatch) => {
    ideaService.getDraft(id).then(
      (idea) => {
        dispatch(success(idea));
          dispatch(ideaCreateDialogsActions.openDialogSendIdea());
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(idea) {
    const payload = {
      idea,
    };

    return { type: ideaCreateConstans.GET_IDEA_USER_DRAFT_SUCCESS, payload };
  }

  function failure(error) {
    return { type: ideaCreateConstans.GET_IDEA_USER_DRAFT_FAILURE, error };
  }
}

function setCanvas(canvas) {
  const payload = {
    canvas,
  };

  return { type: ideaCreateConstans.SET_CANVAS, payload };
}
function setValueCanvas(canvasValueProposition) {
  const payload = {
    canvasValueProposition,
  };

  return { type: ideaCreateConstans.SET_VALUE_CANVAS, payload };
}

function setIdeaCreateChallenge() {
  return { type: ideaCreateConstans.SET_IDEA_CREATE_CHALLENGE };
}

function setSelectedBusiness(businessUnit) {
  return dispatch => {
    dispatch({
      type: ideaCreateConstans.SET_SELECTED_BUSINESS,
      payload: businessUnit
    })
  }
}

function clearSelectedBusiness() {
  return dispatch => {
    dispatch({
      type: ideaCreateConstans.CLEAR_SELECTED_BUSIINESS
    })
  }
}