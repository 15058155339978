import React from 'react';
import { makeStyles } from "@material-ui/core";
import { ImagineGrid } from "..";
import { Skeleton } from "@material-ui/lab";

const useStyle = makeStyles((theme) => ({
  headerWrapper: {
    padding: 16,
  },
  itemHeader: {
    height: 16,
    backgroundColor: "#C0C0C0",
    borderRadius: 4,
  },
  cellWrapper: {
    backgroundColor: "#E9E9E9",
    padding: 16,
  },
  cellItem: {
    backgroundColor: "#C0C0C0",
    borderRadius: 4,
  },
  actionsCell: {
    display: "flex",
    justifyContent: "flex-end",
  },
  skeletonAnimation: {
    animation: "1.5s ease-in-out 0.5s infinite normal none running animation-c7515d"
  }
}));

export const SkeletonTable = ({ cols }) => {
  const classes = useStyle();
  let rows = new Array(10).fill(0);
  let headerRows = Array(cols).fill(0);

  return (
    <ImagineGrid container spacing={2} className={classes.skeletonAnimation}>
      <ImagineGrid container spacing={6} cols={cols} className={classes.headerWrapper}>
        { headerRows.map((item, key) => (
          <ImagineGrid item xs={1}>
            <Skeleton variant="text" className={classes.itemHeader}/>
          </ImagineGrid>
        ))}
      </ImagineGrid>
      { rows.map((item, key) => (
        <ImagineGrid item className={classes.cellWrapper} key={key}>
          <ImagineGrid container spacing={6} cols={cols}>
            { headerRows.map((item2, key2) => (
                <ImagineGrid item xs={1}>
                  <Skeleton variant="text" className={classes.itemHeader}/>
                </ImagineGrid>
            ))}
          </ImagineGrid>
        </ImagineGrid>
      ))}
    </ImagineGrid>   
  );
}
