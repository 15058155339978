import React from 'react';
import {
  makeStyles,
  Box,
  Typography,
  IconButton
} from '@material-ui/core';
import { StatusIdea } from '../Shared';
import { useSelector } from 'react-redux';
import { dateFormat, truncate, history } from '../../helpers';

import ViewIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.neutrals.high.main,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3, 2),
    marginBottom: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    color: theme.palette.neutrals.low.main,
    fontSize: 14,
    fontWeight: 700,
    textTransform: 'capitalize',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  challenge: {
    color: theme.palette.neutrals.low.main,
    fontSize: 14,
    fontWeight: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    paddingTop: theme.spacing(0.5),
  },
  date: {
    color: theme.palette.neutrals.low.light,
    fontSize: 10,
    fontWeight: 400,
    paddingTop: theme.spacing(1.5),
  },
  icon: {
    color: theme.palette.neutrals.low.light,
  },
}));

function CurationCard(props) {
  const classes = useStyles();
  const language = useSelector((state) => state.language);

  function handleView(id) {
    history.push(`/idea/${id}/general`);
  }

  return (
    <Box className={classes.card}>
      <Box className={classes.header}>
        <div style={{ paddingTop: 12 }}>
          <StatusIdea status={props.idea?.statusIdea} />
        </div>
        <IconButton
          className={classes.icon}
          onClick={() => { handleView(props.idea._id) }}
        >
          <ViewIcon />
        </IconButton>
      </Box>
      <Box>
        <Typography
          className={classes.title}
          variant="subtitle2"
          color="primary"
          style={{ flexGrow: 1 }}
          component="h1"
        >
          {truncate(props.idea.name, 70)}
        </Typography>

        <Typography
          className={classes.challenge}
          variant="subtitle2"
          color="primary"
          style={{ flexGrow: 1 }}
          component="p"
        >
          {props.idea.challenge && props.idea.challenge.name
            ? props.idea.challenge.name
            : '-'}
        </Typography>

        <Typography
          className={classes.date}
          variant="subtitle2"
          color="primary"
          style={{ flexGrow: 1 }}
          component="p"
        >
          {dateFormat(props.idea.createdAt, language)}
        </Typography>
      </Box>
    </Box>
  );
}

export { CurationCard };