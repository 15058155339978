import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card, makeStyles } from '@material-ui/core';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';

import { truncate, history } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTrophy: {
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.auxiliary.yellow.main,
  },
  headerTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.neutrals.low.main,
  },
  headerSeeAll: {
    fontSize: 12,
    cursor: 'pointer',
    color: theme.palette.grey.darkest,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  content: {
    marginTop: theme.spacing(2),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 14,
    margin: '4px 0',
  },
  contentIndex: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
  },
  contentUserName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer',
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.text.secondary,

    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  contentUserPoints: {
    fontSize: 13,
    color: theme.palette.text.secondary,
  },
  contentPoints: {
    fontSize: 13,
    color: theme.palette.neutrals.low.light,
    marginLeft: theme.spacing(1),
  },
}));

export function Ranking({ ranking, userId }) {
  const classes = useStyles();
  const { t } = useTranslation();

  function getFirstAndLastName(fullName) {
    const arrName = fullName.split(' ');
    if (arrName.length > 1) {
      return arrName[0] + ' ' + arrName[arrName.length - 1];
    }
    return arrName;
  }

  return (
    <Card className={classes.root} elevation={0}>
      <div className={classes.header}>
        <div className={classes.headerTrophy}>
          <EmojiEventsIcon className={classes.headerIcon} />
          <span className={classes.headerTitle}>ranking</span>
        </div>
        <div
          className={classes.headerSeeAll}
          onClick={() => history.push('/ranking')}
        >
          {t('ver_todos')}
        </div>
      </div>
      <div className={classes.content}>
        {ranking &&
          ranking.slice(0, 5).map((user, index) => (
            <div className={classes.contentBox} key={index}>
              <div>
                <span className={classes.contentIndex}>{user.position}º</span>
                <span 
                  className={classes.contentUserName}
                  onClick={() => history.push(`/profile/${user.userId}`)}
                >
                  {truncate(getFirstAndLastName(user.userName), 15)}
                </span>
              </div>
              <div>
                <span className={classes.contentUserPoints}>
                  {parseInt(user.score)}
                </span>
                <span className={classes.contentPoints}>pts</span>
              </div>
            </div>
          ))}
      </div>
    </Card>
  );
}