import React from 'react';
import {
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
} from '@material-ui/core';
import { DialogCustomTitle } from './DialogCustomTitle';
import { ideaCreateActions, ideaCreateDialogsActions, multiAreaCascadeActions } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Upload, UploadButton } from '../Upload';
import InfoIcon from '@material-ui/icons/Info';
import { dialogCss } from './dialogCss';
import dialogFileSvg from '../../assets/imgs/dialog-file.svg';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DescriptionIcon from '@material-ui/icons/Description';
import { DialogCreateIdea } from './DialogCreateIdea';
import config from '../../config';
import { useWindowSize } from '../../hooks';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    textTransform: 'lowercase',
  },
  alert: {
    color: theme.palette.grey[500],
    paddingBottom: 24,
  },
  strong: {
    fontWeight: 'bold',
  },
  dividerBottom: {
    borderBottom: 'none',
  },
}));

function DialogFile() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const dialogClasses = dialogCss();
  const { dialogFile, fromHistory } = useSelector((state) => state.ideaCreateDialogs);
  const [width] = useWindowSize();
  const { t } = useTranslation();

  function next() {
    if (fromHistory[fromHistory.length - 1] === 'DIALOG_SEND_IDEA') {
      dispatch(ideaCreateDialogsActions.openDialogSendIdea());
      return;
    }

    dispatch(ideaCreateDialogsActions.openDialogTitleDescription());
  }

  function previous() {
    dispatch(ideaCreateDialogsActions.previousDialog())
  }

  function close() {
    if (width < config.RESPONSIVE_MOBILE) {
      dispatch(ideaCreateDialogsActions.openDialogClose());
    } else {
      dispatch(ideaCreateActions.clearAll());
      dispatch(ideaCreateDialogsActions.close());
    }
    dispatch(multiAreaCascadeActions.clearAll());
  }


  return (
    <DialogCreateIdea open={dialogFile}>
      <DialogCustomTitle id="customized-dialog-title" onClose={close}>
        {t('publicar_arquivo')}
      </DialogCustomTitle>
      <DialogContent dividers className={classes.dividerBottom}>
        <Upload
          type="file"
          img={dialogFileSvg}
          name="arquivo"
          message={t('insira_um_arquivo_apresentando_a_sua_ideia')}
          accept=".pdf,application/pdf,.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.doc,application/msword,.ppt,application/vnd.ms-powerpoint,.pptx,application/vnd.openxmlformats-officedocument.presentationml.presentation"
          maxSize={26214400}
        />
      </DialogContent>
      <DialogActions className={dialogClasses.containerAction}>
        <div style={{ width: '100%' }}>
          <div className={classes.alert}>
            <InfoIcon fontSize="small" style={{ marginRight: 6 }} /> {' '}
            {t('o_arquivo_deve_ser_em_formato')}{' '}
             <span className={classes.strong}>pdf</span>,{' '}
            <span className={classes.strong}>doc</span> {t('ou')}{' '}
            <span className={classes.strong}>ppt</span>{t('e')}{' '}
            {t('possuir_tamanho_maximo_de')}{' '}
             <span className={classes.strong}>25mb</span>.
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {fromHistory.length > 0 && (
                <Button
                  color="primary"
                  onClick={previous}
                  startIcon={<ArrowBackIcon />}
                  className={dialogClasses.button}
                >
                  {t('voltar')}
                </Button>
              )}
            </div>
            <div>
              <UploadButton
                type="file"
                text={t('adicionar_documento')}
                icon={<DescriptionIcon />}
                className={dialogClasses.button}
              />
              <Button
                className={dialogClasses.button}
                variant="contained"
                color="primary"
                startIcon={<ArrowForwardIcon />}
                onClick={() => next()}
              >
                {t('avancar')}
              </Button>
            </div>
          </div>
        </div>
      </DialogActions>
    </DialogCreateIdea>
  );
}

export { DialogFile };
